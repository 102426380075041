.tables {
  height: 100vh;
  box-sizing: border-box;
  padding: 20px 40px 20px 20px;
}

.tables__wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-blue);
  width: 174px;
  gap: 20px;
  border-radius: var(--main-border-radius);
}

.tables__list {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  max-height: 85%;
  width: 100%;
}

.tables__add {
  background-color: var(--color-blue);
  font-family: var(--roboto);
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weigth);
  line-height: var(--main-line-height);
  color: var(--color-black);
  border-radius: var(--main-border-radius);
  width: min-content;
  height: min-content;
  border: 0;
  padding: 20px;
  cursor: pointer;
  align-self: center;
}

.tables__add:hover {
  color: var(--color-white);
}

@media (max-width: 1200px) {
  .tables {
    padding: 20px 40px;
  }

  .tables__wrapper {
    width: 60px;
  }
}
