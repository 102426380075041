.left_block {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--blocks-margin);
  align-items: center;
  padding: 20px 30px 20px;
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  max-width: 352px;
  box-sizing: border-box;
  overflow: hidden;
}

@media (max-width: 1200px) {
  .left_block {
    max-width: 200px;
    padding: 20px 15px;
  }
}
