.custom_right_desk {
  height: 100vh;
  width: max-content;
}

.custom_right_desk__wrapper {
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-width: 304px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.custom_right_desk__buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
  margin: 0 auto;
}

.custom_right_desk__buttons button {
  padding: 10px 10px;
}

@media (max-width: 1200px) {
  .custom_right_desk__wrapper {
    min-width: 228px;
  }

  .custom_right_desk__buttons {
    width: 100%;
  }
}
