.chess__container {
  max-width: calc(100vh - 40px);
  width: 100%;
  height: min-content;
  max-height: calc(100% - 20px);
  display: grid;
  grid-template-areas:
    'a b b'
    'a b b'
    '. c c';
  grid-template-columns: min-content 1fr 1fr;
  background-color: var(--color-white);
  border-radius: var(--main-border-radius);
  padding: 10px;
  box-sizing: border-box;
}

.chess__number_block {
  grid-area: a;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 20px 0 10px;
}

.chess__number {
  font-family: var(--roboto);
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weigth);
  line-height: var(--main-line-height);
  color: var(--color-black);
}

.chess__board {
  grid-area: b;
  width: 100%;
}

.chess__letter_block {
  grid-area: c;
  display: flex;
  justify-content: space-around;
  padding: 10px 0 10px 0;
}

.chess__letter {
  font-family: var(--roboto);
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weigth);
  line-height: var(--main-line-height);
  color: var(--color-black);
  text-transform: uppercase;
}
