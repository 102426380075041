.item {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.item__black {
  align-items: flex-end;
  background-color: var(--color-blue);
}

.item__white {
  align-items: flex-start;
  background-color: var(--color-white);
}

.item__span {
  display: block;
  font-family: var(--roboto);
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weight);
  line-height: var(--main-line-height);
  padding: 5px 22px;
  text-transform: uppercase;
}
