.main_button {
  background-color: var(--color-white);
  font-family: var(--roboto);
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weigth);
  line-height: var(--main-line-height);
  color: var(--color-black);
  border: 0;
  border-radius: var(--main-border-radius);
  padding: 19px 10px;
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
}

.main_button:hover {
  background-color: var(--hover-white);
  filter: var(--hover-filter);
}

.main_button:active {
  background-color: var(--active-blue);
  outline: 3px solid var(--color-white);
  color: var(--color-white);
}

.main_button:disabled {
  background-color: #6d6f75;
  cursor: auto;
}

.main_button__cancel {
  background-color: var(--color-red);
  color: var(--color-white);
}

.main_button__cancel:hover {
  background-color: var(--hover-red);
  filter: var(--hover-filter);
}

.main_button__cancel:active {
  background-color: var(--active-red);
  outline: 0;
}

.main_button__cancel:disabled {
  background-color: #6d6f75;
  cursor: auto;
}

.main_button__control {
  background-color: var(--color-light-blue);
}

.main_button__active {
  background-color: var(--active-blue);
  outline: 3px solid var(--color-white);
  color: var(--color-white);
}

.main_button__active:hover {
  color: var(--color-black);
}
