.control {
  background-color: var(--color-white);
  border-radius: var(--main-border-radius);
  width: 100%;
}

.control__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 44px 0;
  width: 100%;

  font-family: var(--roboto);
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weight);
  line-height: var(--main-line-height);
}

.control__desk_number {
  margin-bottom: 25px;
}

.control__title {
  margin-bottom: 50px;
  position: relative;
}

.control__title::after {
  content: '';
  display: block;
  position: absolute;
  width: 112px;
  height: 1px;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-black);
}

.control__button_block {
  display: flex;
  flex-direction: column;
  width: 174px;
}

.control__span {
  margin-bottom: 20px;
}

.control__buttons {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.control__buttons:first-of-type {
  margin-bottom: 50px;
}

.control__buttons:first-of-type::after {
  content: '';
  display: block;
  position: absolute;
  width: 112px;
  height: 1px;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--color-black);
}

.control__buttons:last-of-type {
  margin-bottom: 20px;
}

.modal__content {
  background-color: var(--color-light-blue);
  width: 600px;
  border-radius: var(--main-border-radius);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 15px;
  box-sizing: border-box;
}

.modal__title {
  text-align: center;
  font-family: var(--roboto);
  font-weight: var(--main-font-weigth);
}

.modal__buttons {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (max-width: 1200px) {
  .control__wrapper {
    padding: 20px 0;
  }

  .control__button_block {
    width: 150px;
  }
}
