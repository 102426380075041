.chess_select {
  background-color: var(--color-white);
  height: 100%;
  max-height: 392px;
  padding: 11px;
  box-sizing: border-box;
  border-radius: var(--main-border-radius);
}

.chess_select__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  height: 100%;
  background-color: var(--color-light-blue);
}
