.login__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__window {
  font-family: var(--roboto);
  width: 400px;
  margin-top: 200px;
  padding: 40px;
  background: #3e3e3e;
  box-shadow: 0 15px 25px #727dd8;
  border-radius: 10px;
}

.login__title {
  margin: 0 0 30px;
  padding: 0;
  color: #ffffff;
  text-align: center;
}

.login__input {
  position: relative;
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #ffffff;
  outline: none;
  background: transparent;
}

.login__input:hover + label {
  top: -85px;
  left: 0;
  color: #727dd8;
  font-size: 12px;
}

.login__input:valid + label {
  top: -85px;
  left: 0;
  color: #727dd8;
  font-size: 12px;
}

.login__label {
  position: relative;
  left: 0;
  top: -60px;
  padding: 10px 0;
  font-size: 16px;
  color: #ffffff;
  pointer-events: none;
  transition: 0.5s;
}

.login__buttons {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.login__submit {
  padding: 10px 20px;
  color: #cbbddb;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  letter-spacing: 4px;
  border: 1px solid #727dd8;
  margin: auto;
  background: transparent;
}

.login__submit:hover {
  background: #727dd8;
  color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 5px #727dd8, 0 0 25px #727dd8, 0 0 50px #727dd8,
    0 0 100px #727dd8;
}

.login__register_block {
  font-size: 14px;
  text-decoration: none;
  color: #ffffff;
  margin: auto;
  width: 60%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.login__link {
  margin: auto;
  color: #727dd8;
  text-decoration: none;
}
