.moves {
  background-color: var(--color-white);
  border-radius: var(--main-border-radius);
  width: 100%;
}

.moves__wrapper {
  padding: 20px 60px;
  box-sizing: border-box;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.moves__span {
  font-family: var(--roboto);
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weigth);
  line-height: var(--main-line-height);
  color: var(--color-black);
  text-transform: uppercase;
  display: block;
  position: relative;
  width: max-content;
}

.moves__span_overlined::before {
  content: '';
  position: absolute;
  top: -5px;
  width: 100%;
  height: 1px;
  background-color: var(--color-black);
}
