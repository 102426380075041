.modal__wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: var(--color-white);
  width: 300px;

  display: flex;
  flex-direction: column;
  border-radius: var(--main-border-radius);
  font-family: var(--roboto);
  padding: 50px;
  text-align: center;
}

.modal__title {
  margin: 0;
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weight);
  line-height: var(--main-line-height);
  text-transform: uppercase;
  margin-bottom: 30px;
}

.modal__numbering {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 40px;
}

.modal__numbering span {
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weight);
  line-height: var(--main-line-height);
  text-transform: uppercase;
}

.modal__numbering input {
  background-color: transparent;
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weight);
  line-height: var(--main-line-height);
  border: 1px solid var(--color-blue);
  text-align: center;
  padding: 10px;
  border-radius: var(--main-border-radius);
}

.modal__numbering::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 50%;
  height: 1px;
  background-color: #000000;
  bottom: -20px;
}

.modal__choosing {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 40px;
}

.modal__choosing span {
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weight);
  line-height: var(--main-line-height);
  text-transform: uppercase;
}

.modal__choosing button {
  background-color: var(--color-light-blue);
}

.modal__choosing::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 50%;
  height: 1px;
  background-color: #000000;
  bottom: -20px;
}
