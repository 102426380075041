.chess_button {
  background-color: transparent;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: 0;
  cursor: pointer;
}

.chess_button:hover {
  background-color: var(--hover-white);
}

.chess_button:active {
  background-color: var(--active-blue);
}
