.custom_left_desk {
  display: flex;
  flex-direction: column;
  gap: var(--blocks-margin);
  align-items: center;
  padding: 20px 30px 0px;
  height: 100vh;
  width: 100%;
  max-width: 292px;
}

@media (max-width: 1200px) {
  .custom_left_desk {
    max-width: 234px;
    padding: 20px 15px 0;
    box-sizing: border-box;
  }
}
