.modal__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 400px;
  height: 100px;
  background-color: var(--color-white);
  padding: 10px;
}

.modal__list {
  display: flex;
  width: 100%;
  height: 100%;
  gap: 5px;
}

.modal__button {
  width: 100%;
  background-color: transparent;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: 0;
  cursor: pointer;
}
