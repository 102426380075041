.move_block {
  background-color: var(--color-light-blue);
  box-sizing: border-box;
  border: 5px solid #8aff88;
  border-radius: var(--main-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  padding: 15px 0;
  min-height: 85px;
}

.move_block__button {
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  border: 0;
}

.move_block__span {
  font-family: var(--roboto);
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weight);
  line-height: var(--main-line-height);
  text-transform: uppercase;
}

.move_block__arrow_block {
  display: flex;
  align-items: center;
  width: max-content;
  gap: 10px;
}

.move_block__arrow {
  background-image: url('../../images/svg/Arrow.svg');
  width: 43px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

.loader {
  display: inline-block;
  font-size: 40px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 0.3em;
  position: relative;
}

.loader span {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.2em;
  height: 0.2em;
  border-radius: 50%;
  background-color: #333;
  animation: loader-animation 0.6s infinite alternate;
}

.loader span:nth-of-type(2) {
  left: 0.3em;
  animation-delay: 0.2s;
}

.loader span:nth-of-type(3) {
  left: 0.6em;
  animation-delay: 0.4s;
}

@keyframes loader-animation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
