.history {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--color-white);
  border-radius: var(--main-border-radius);
  position: relative;
}

.history__title {
  font-family: var(--roboto);
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weight);
  line-height: var(--main-line-height);
  padding: 20px 0;
  text-align: center;
  display: block;
}

.history__list {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  overflow: auto;
}
