.table_button {
  width: 100%;
  background-color: var(--color-light-blue);
  font-family: var(--roboto);
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weigth);
  line-height: var(--main-line-height);
  color: var(--color-black);
  border-radius: var(--main-border-radius);
  border: 0;
  padding: 19px 10px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
}

.table_button span {
  margin-right: 4px;
  text-transform: uppercase;
}

@media (max-width: 1200px) {
  .table_button span {
    display: none;
  }
}

.table_button:hover {
  background-color: var(--hover-white);
  filter: var(--hover-filter);
}

.table_button__active {
  background-color: var(--active-blue);
  border: 3px solid var(--color-white);
  color: var(--color-white);
}

.table_button__active:hover {
  background-color: var(--active-blue);
  border: 3px solid var(--active-blue);
  color: var(--color-white);
}

.popup__button {
  background-color: var(--color-white);
  font-family: var(--roboto);
  font-size: var(--main-font-size);
  font-weight: var(--main-font-weigth);
  line-height: var(--main-line-height);
  padding: 10px 0;
  border-radius: var(--main-border-radius);
  border: 0;
  width: 174px;
  text-transform: uppercase;
  cursor: pointer;
}

.popup__button:hover {
  color: var(--color-white);
  background-color: var(--active-blue);
}

@media (max-width: 1024px) {
  .table_button span {
    display: none;
  }
}

.modal__content {
  background-color: var(--color-light-blue);
  width: 600px;
  border-radius: var(--main-border-radius);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 15px;
  box-sizing: border-box;
}

.modal__title {
  text-align: center;
  font-family: var(--roboto);
  font-weight: var(--main-font-weigth);
}

.modal__buttons {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
