.app {
  background-color: var(--color-dark-grey);
  height: 100vh;
  overflow: hidden;
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
}
